<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.patient_service") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-main v-loading="waiting">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span> {{ $t("message.order") }}</span>
          </div>
          <div>
            <el-table :data="tableOrderData" style="width: 100%">
              <el-table-column label="№" width="110">
                <template slot-scope="scope"> # {{ scope.row.id }} </template>
              </el-table-column>
              <el-table-column prop="diagnos" label="Клинический диагноз">
              </el-table-column>
              <el-table-column
                prop="partner_clinic.name"
                label="Партнер клиника"
              >
              </el-table-column>
              <el-table-column prop="department.name" label="Отдел">
              </el-table-column>
              <el-table-column
                prop="partner_doctor.name"
                label="Партнер доктор"
              >
              </el-table-column>
              <el-table-column prop="blok" label="Блок"> </el-table-column>
              <el-table-column prop="vial_quantity" label="Количество флаконов">
              </el-table-column>
              <el-table-column label="Материал">
                <template slot-scope="scope">
                  {{ scope.row.material ? scope.row.material.name : "" }}
                </template>
              </el-table-column>
              <el-table-column prop="created_at" label="Дата приема заказа">
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24" class="mt-2">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span> {{ $t("message.patient") }}</span>
          </div>
          <div>
            <el-table :data="tablePatientData" style="width: 100%">
              <el-table-column label="№" width="110">
                <template slot-scope="scope"> # {{ scope.row.id }} </template>
              </el-table-column>
              <el-table-column
                prop="first_name"
                :label="$t('message.first_name')"
                width="150"
              >
              </el-table-column>
              <el-table-column prop="surname" :label="$t('message.surname')">
              </el-table-column>
              <el-table-column
                prop="patronymic"
                :label="$t('message.patronymic')"
              >
              </el-table-column>
              <el-table-column prop="born_date" :label="$t('message.dob')">
              </el-table-column>
              <el-table-column prop="gender" :label="$t('message.gender')">
              </el-table-column>
              <el-table-column prop="code" :label="$t('message.code')">
              </el-table-column>
              <el-table-column prop="phone" :label="$t('message.phone')">
              </el-table-column>
              <el-table-column :label="$t('message.social')">
                <template slot-scope="scope">
                  {{ scope.row.social ? scope.row.social.name : "" }}
                </template>
              </el-table-column>
              <el-table-column prop="created_at" :label="$t('message.date')">
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24" class="mt-2">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span> Адрес пациента</span>
          </div>
          <div>
            <el-table :data="tablePatientData" style="width: 100%">
              <el-table-column :label="$t('message.country')">
                <template slot-scope="scope">
                  {{ scope.row.state ? scope.row.state.name : "" }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.city')">
                <template slot-scope="scope">
                  {{ scope.row.city ? scope.row.city.name : "" }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.region')">
                <template slot-scope="scope">
                  {{ scope.row.region[0] ? scope.row.region[0].name : "" }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.street')">
                <template slot-scope="scope">
                  {{ scope.row.street }}
                </template>
              </el-table-column>
              <!-- <el-table-column :label="$t('message.district')">
                <template slot-scope="scope">
                  {{ scope.row.district }}
                </template>
              </el-table-column> -->
              <el-table-column :label="$t('message.house')">
                <template slot-scope="scope">
                  {{ scope.row.home }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.flat')">
                <template slot-scope="scope">
                  {{ scope.row.flat }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24" class="mt-2">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span> {{ $t("message.order_service") }}</span>
          </div>
          <div>
            <el-table :data="tableServicesData" style="width: 100%">
              <el-table-column type="index" label="#" width="80">
              </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('message.service_name')"
                width="280"
              >
              </el-table-column>
              <el-table-column prop="price" :label="$t('message.price2')">
                <template slot-scope="scope">
                  {{ scope.row.price | formatMoney }}
                </template>
              </el-table-column>
              <el-table-column
                prop="discount_price"
                :label="$t('message.discount')"
              >
              </el-table-column>
              <el-table-column prop="code" :label="$t('message.code')">
              </el-table-column>
              <el-table-column prop="lifetime" label=" Срок услуги ">
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-main>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["drawer", "open", "order"],
  data() {
    return {
      waiting: false,
      form: {},
      tablePatientData: [],
      tableOrderData: [],
      tableServicesData: [],
    };
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.loadOrder();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "orders/rules",
      model: "orders/relation_model",
      columns: "orders/columns",
    }),
  },
  methods: {
    ...mapActions({
      show: "orders/relationShow",
    }),
    loadOrder() {
      const query = { id: this.order.id, relation: true };
      this.waiting = true;
      this.show(query)
        .then((res) => {
          this.loadModel();
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
        });
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableOrderData = [JSON.parse(JSON.stringify(this.model))];
      this.tableServicesData = JSON.parse(
        JSON.stringify(this.model.order_services)
      );
    },
    close() {
      this.$emit("c-close", { drawer: "drawerOrderShow" });
    },
    parent() {
      return this.$parent.$parent;
    },
  },
};
</script>